.manager_button {
  position: fixed;
  top: 220px;
  right: 0px;
  z-index: 9999999999999;
  button {
    background-color: #fff;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 12px 7px 14px;
    transition: all 0.3s ease;
    opacity: 0.3;
    &:hover {
      padding: 10px 22px 7px 14px;
      opacity: 0.8;
    }
  }
}