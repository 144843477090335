.lockscreen {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  .ls_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: 100vh;
    width: 400px;
    position: relative;
    z-index: 99999999999;
    .ls_wrap_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  .ls_title {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: start;
    margin-bottom: 42px;
    width: 100%;
  }
  .ls_form {
    width: 100%;
    .ls_form_user {
      display: flex;
      flex-direction: row;
      align-items: center;
      .ls_form_user__ava {
        width: 42px;
        height: 42px;
        border-radius: 100px;
        margin-right: 12px;
        min-width: 42px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .ls_form_user__data {
        width: 100%;
        div {
          line-height: 1;
          color: #fff;
          &:nth-child(1) {
            font-size: 20px;
            font-weight: 700;
          }
          &:nth-child(2) {
            font-size: 15px;
            font-weight: 400;
            margin-top: 4px;
          }
        }
      }
    }
    .ls_form_item {
      margin: 32px 0;
      width: 100%;
      input {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 100px;
        border: none;
        outline: none;
        font-size: 20px;
        text-align: center;
        line-height: 1;
        padding: 10px 24px 7px;
        color: #fff;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    .ls_form_action {
      text-align: center;
      width: 100%;
      button {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 100px;
        border: none;
        outline: none;
        font-size: 18px;
        text-align: center;
        line-height: 1;
        padding: 10px 24px 7px;
        color: #fff;
        transition: all 0.3s ease;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        &:active {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
  .ls_copys {
    width: 100%;
    margin-top: 62px;
    color: #fff;
    opacity: 0.5;
    text-align: center;
  }
}

.page_blink {
  background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%);
  width: 1688px;
  height: 1688px;
  position: absolute;
  left: -734px;
  margin: auto;
  transition: all 2s ease;
  animation: rotatta 12s infinite;
  &.blue {
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%);
    animation: rotatta2 12s infinite;
  }
  &.right {
    left: unset !important;
    right: -734px !important;
  }
  &.toped {
    margin-top: -600px;
  }
  &.toped2 {
    margin-top: -1000px;
  }
  &.toped4 {
    margin-top: -1400px;
  }
  &.toped3 {
    margin-top: -250px;
  }
}

@media screen and (max-width: 548px) {
  .page_blink {
    transition: all 0.4s ease;
    background: radial-gradient(ellipse at center, rgba(36, 120, 189, 0.7) 0%, transparent 55%) !important;
    &.blue {
      background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
    }
    &.reddz {
      background: radial-gradient(ellipse at center, rgba(255, 50, 50, 0.7) 0%, transparent 55%) !important;
      top: 820px;
    }
    &.toped55 {
      margin-top: -690px !important;
    }
    &.toped {
      margin-top: -650px !important;
    }
  }
}

@keyframes rotatta {
  0% {
    transform: rotate(0deg) translateY(100px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateY(100px) rotate(-360deg);
  }
}

@keyframes rotatta2 {
  0% {
    transform: rotate(0deg) translateY(100px) rotate(0deg);
  }
  100% {
    transform: rotate(-360deg) translateY(100px) rotate(360deg);
  }
}