.ticket_card {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: " ";
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(-45deg, #f10000 0%, #000 50%, #0068b7 100%);
    transform: scale(0.5);
  }
  .wrapper {
    position: relative;
    z-index: 9999;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    color: #fff;
    font-size: 100px;
    font-weight: 900;
    line-height: 1;
  }
  h2 {
    color: #fff;
    line-height: 1;
    font-size: 32px;
    font-weight: 900;
    opacity: 0.6;
    font-family: 'RobotoCondensed';
    // display: none;
  }
  .ticket_num {
    margin-top: 42px;
    padding: 30px 32px 10px;
    letter-spacing: 10px;
    font-size: 102px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    line-height: 1;
    width: fit-content;
    font-weight: bolder;
    
  }
}